import { Card, CardContent } from '@naamdeo/shadcn-ui/components/card';
import { Typography } from '@naamdeo/shadcn-ui/components/typography';
import placeholderImgUrl from './placeholder.png';

export default function PlaceholderContent() {
  return (
    <Card className="mt-6 rounded-lg border-none">
      <CardContent className="p-6">
        <div className="flex min-h-[calc(100vh-56px-64px-20px-24px-56px-48px)] items-center justify-center">
          <div className="relative flex flex-col">
            <img src={placeholderImgUrl} alt="Placeholder" width={500} height={500} />
            <div className="mt-4 w-full text-center">
              <Typography>This page is currently in development</Typography>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
